//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { removePTags } from '~/utils'

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      swiperOption: {
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },

  computed: {
    ImageVerticalformat() {
      // return one of many images in immage array
      return this.data.images_verticalformat[
        Math.floor(Math.random() * this.data.images_verticalformat.length)
      ]
    },

    ImagesLandscapeformat() {
      // return one of many images in immage array
      return this.data.images_landscapeformat[
        Math.floor(Math.random() * this.data.images_landscapeformat.length)
      ]
    },

    Images() {
      return [
        ...this.data.images_landscapeformat,
        ...this.data.images_verticalformat,
      ]
    },
  },

  methods: {
    removePTags(text) {
      return removePTags(text)
    },

    genAltText(img) {
      if (img[`alt_${this.$i18n.locale}`])
        return img[`alt_${this.$i18n.locale}`]
      // FALLBACK DE
      return img.alt ? img.alt : ''
    },
  },
}
