//
//
//
//

import AppImage from '@teamnovu/vue-cloudinary-image-statamic'

export default {
  components: {
    BaseAppImage: AppImage,
  },

  props: {
    src: {
      type: [String, Object],
      required: true,
    },
  },

  methods: {
    genAltText(img) {
      if (img[`alt_${this.$i18n.locale}`]) {
        return img[`alt_${this.$i18n.locale}`]
      }
      // FALLBACK DE
      return img.alt ? img.alt : undefined
    },
  },
}
